import { Price, Product, ProductVariant, Session } from 'types/schema';
import { isChild } from './object';

export function getPrimaryProductShowcase(product: Product) {
  return product.product_showcases?.find((c) => c.is_primary);
}

export function mapProductVariants(variants?: ProductVariant[]) {
  return (variants || [])
    .filter(({ values }) => (values || []).length > 0)
    .map(({ id, name, values, code }) => {
      return {
        id,
        name,
        code,
        options: (values || []).map(
          ({ id, metadata, name }) =>
            ({
              id,
              code,
              name,
              value: id?.toString(),
              url: (metadata as { url: string })?.url,
            } as {
              id: number;
              name: string;
              value: string;
              url: string;
            }),
        ),
      };
    });
}

export function mapOptionVariants(session: Session) {
  return (session.item_variants || []).map(({ id, values }) => {
    const [selectedId] = values || [];
    const [{ values: variantValues, name: variantName, code }] = (
      session.product?.product_variants || []
    )?.filter((v) => v.id === id);

    const [{ metadata, name }] = (variantValues || []).filter(
      (v) => v.id === selectedId,
    );

    return {
      id,
      code,
      variantName: variantName || '',
      metadata,
      value: selectedId,
      name: name as string,
    };
  });
}

export type ProductVariantOptions = ReturnType<typeof mapOptionVariants>;

export function getProductPriceBySelectedVariants(
  prices: Price[],
  selectedVariants: { variantId: number; optionId: number }[],
): Required<Price> {
  const variants = selectedVariants.reduce(
    (fin, { variantId, optionId }) => ({ ...fin, [variantId]: optionId }),
    {},
  );

  const selectedPrice = prices.find((price) => {
    const priceVariants = price.variants?.reduce(
      (fin, { id, values }) => ({ ...fin, [`${id}`]: values?.[0] }),
      {},
    );

    return isChild(priceVariants, variants);
  });

  if (!selectedPrice && prices.length) {
    return (prices.find((price) => price.is_default) ||
      prices[0]) as Required<Price>;
  }

  return selectedPrice as Required<Price>;
}

export function isPhotoTemplateProduct(slug: string = '') {
  if (!slug) {
    return false;
  }

  return !!slug.match(/template.*/i)?.length;
}

export function isPhotoFrameProduct(slug: string = '') {
  if (!slug) {
    return false;
  }

  return !!slug.match(/frame/i)?.length;
}

export function isPhotobookProduct(slug: string = '') {
  return slug === 'photobook';
}

export function isCrystalFrameProduct(slug: string = '') {
  if (!slug) {
    return false;
  }

  return !!slug.match(/crystal/i)?.length;
}
