import {
  isPhotobookProduct,
  isPhotoTemplateProduct,
  isCrystalFrameProduct,
} from 'utils/product';
import { StaticPageKey, STATIC_PAGES } from './static-pages';

/* eslint-disable newline-before-return */
export const ROUTES = {
  HOME: '/',
  PAGE_NOT_FOUND: '/404',
  CHECKOUT: '/checkout',
  ORDER_TRACKING: '/order-tracking',
  ORDERS: '/orders',
  ORDER_DETAIL: (id: number) => `/orders/${id}`,
  CART: '/cart',
  PHOTO_FRAME: (params?: { sessionId: string; productSlug: string }) => {
    if (params) {
      const { sessionId, productSlug } = params;
      return `/photoframe/${productSlug}/${sessionId}`;
    }
    return `/photoframe/[productSlug]/[sessionId]`;
  },
  PHOTOBOOK: (params?: { sessionId: string; productSlug: string }) => {
    if (params) {
      const { sessionId, productSlug } = params;
      return `/photobook/${productSlug}/${sessionId}`;
    }
    return `/photobook/[productSlug]/[sessionId]`;
  },
  PHOTO_TEMPLATES: (params?: { sessionId: string; productSlug: string }) => {
    if (params) {
      const { sessionId, productSlug } = params;
      return `/photo-templates/${productSlug}/${sessionId}`;
    }
    return `/photo-templates/[productSlug]/[sessionId]`;
  },
  CRYSTAL_FRAME: (params?: { sessionId: string; productSlug: string }) => {
    if (params) {
      const { sessionId, productSlug } = params;
      return `/crystal-frame/${productSlug}/${sessionId}`;
    }
    return `/crystal-frame/[productSlug]/[sessionId]`;
  },
  SELLER: (id: number) => `/sellers/${id}`,
  PRODUCT: (params?: { sellerSlug: string; slug: string }) => {
    if (params) {
      const { sellerSlug, slug } = params;
      return `/sellers/${sellerSlug}/${slug}`;
    }
    return '/sellers/[sellerSlug]/[productSlug]';
  },
  STATIC_PAGE: (pageSlug?: StaticPageKey) => {
    if (pageSlug) {
      const slug = STATIC_PAGES[pageSlug];
      return `/p/${slug}`;
    }
    return '/p/[pageSlug]';
  },
};

export function getSellerProductRoute(
  slug: string,
  params?: { productSlug: string; sessionId: string },
) {
  if (isPhotobookProduct(slug)) {
    return ROUTES.PHOTOBOOK(params);
  }

  if (isPhotoTemplateProduct(slug)) {
    return ROUTES.PHOTO_TEMPLATES(params);
  }

  if (isCrystalFrameProduct(slug)) {
    return ROUTES.CRYSTAL_FRAME(params);
  }

  return ROUTES.PHOTO_FRAME(params);
}
