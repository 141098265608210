export function shallowEqual(
  o1?: Record<string, any>,
  o2?: Record<string, any>,
) {
  if (o1 === o2) {
    return true;
  }

  if (!o1 || !o2) {
    return false;
  }

  const keys1 = Object.keys(o1);
  const keys2 = Object.keys(o2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    if (o1[key] !== o2[key]) {
      return false;
    }
  }

  return true;
}

export function isChild(o1?: Record<string, any>, o2?: Record<string, any>) {
  if (o1 === o2) {
    return true;
  }

  if (!o1 || !o2) {
    return false;
  }

  const keys1 = Object.keys(o1);

  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    if (o1[key] !== o2[key]) {
      return false;
    }
  }

  return true;
}
